import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import getAxios from "../../../../services/axios-get";
import putAxios from "../../../../services/axios-put";
import postAxios from "../../../../services/axios-post";
import deleteAxios from "../../../../services/axios-delete";
Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      partnerSearch: null,
      deleteDialog: false,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "mobilePhone" },
        { text: "Address", value: "address" },
        { text: "Edit", value: "edit", sortable: false },
        { text: "Delete", value: "delete", sortable: false },
      ],
      partnerList: [],
      addDialog: false,
      addPartner: {
        name: null,
        emaill: null,
        description: null,
        mobilePhone: null,
        address: null,
        status: null,
      },
      editDialog: false,
      editPartner: {
        id: null,
        name: null,
        email: null,
        description: null,
        mobilePhone: null,
        address: null,
        status: null,
      },
      partnerStatusList: [
        { text: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
        { text: "APPROVED", value: "APPROVED" },
        { text: "ACTIVE", value: "ACTIVE" },
        { text: "REJECTED", value: "REJECTED" },
      ],
      rules: {
        name: [v => !!v],
        email: [v => !!v],
      },
      delPartner: {
        id: "",
        name: "",
      },
      sortValue: null,
      sortBy: null,
      sortDesc: null,
    };
  },
  mounted() {
    this.fetchPartnerList();
  },
  methods: {
    sortItems({ sortBy, sortDesc }) {
      let columnName = sortBy[0];
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = null;
      }
      this.fetchPartnerList();
    },
    searchPartner() {
      this.fetchPartnerList();
    },
    fetchPartnerList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.partnerSearch,
        sort: self.sortValue,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/partner`,
        params
      ).then(res => {
        if (res.data) {
          self.partnerList = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchPartnerList();
    },
    editButtonClick(item) {
      this.editDialog = true;
      this.setEditItemInfo(item);
    },
    setEditItemInfo(item) {
      this.editPartner.name = item.name;
      this.editPartner.status = item.status;
      this.editPartner.email = item.email;
      this.editPartner.dealerId = item.id;
      this.editPartner.description = item.description;
      this.editPartner.mobilePhone = item.mobilePhone;
      this.editPartner.address = item.address;
    },
    resetEditItemInfo() {
      this.editPartner.name = null;
      this.editPartner.status = null;
      this.editPartner.email = null;
      this.editPartner.dealerId = null;
      this.editPartner.description = null;
      this.editPartner.mobilePhone = null;
      this.editPartner.address = null;
    },
    resetAddItemInfo() {
      this.addPartner.name = null;
      this.addPartner.status = null;
      this.addPartner.id = null;
      this.addPartner.description = null;
      this.addPartner.mobilePhone = null;
      this.addPartner.address = null;
    },
    async doAddPartner(addPartner) {
      const self = this;
      try {
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/addPartner`,
          addPartner
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.addDialog = false;
            self.fetchPartnerList();
            self.resetAddItemInfo();
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    async doEditPartner() {
      const self = this;
      try {
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/updDealer`,
          this.editPartner
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.editDialog = false;
            self.fetchPartnerList();
            self.resetEditItemInfo();
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    deleteButtonClick(item) {
      this.delPartner.id = item.id;
      this.delPartner.name = item.name;
      this.deleteDialog = true;
    },
    async doDelPartner() {
      const self = this;
      try {
        const param = {
          dealerId: self.delPartner.id,
        };
        deleteAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/delDealer`,
          param
        ).then(res => {
          if (res.data) {
            self.deleteDialog = false;
            self.fetchPartnerList();
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
